import styles from "./styles.module.css";
import {useEffect, useState, Children} from "react";
import { keyframes } from "@emotion/react";
import { Reveal } from "react-awesome-reveal";

const fadeUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export default function TwoColumns({children}) {
  const arrayChildren = Children.toArray(children);

  useEffect(() => {
    console.log(children);
    console.log(arrayChildren);
  },[]);

  return (
    <div className={styles.container}>
      <div className={styles.column}>
      <Reveal keyframes={fadeUp} triggerOnce delay={300} fraction={.2} duration={1100}>
        {arrayChildren[0]}
      </Reveal>
      </div>
      <div className={styles.column}>
      <Reveal keyframes={fadeUp} triggerOnce delay={500} fraction={.2} duration={1300}>
        {arrayChildren[1]}
      </Reveal>
      </div>  
    </div>
  );
}
