import styles from "./styles.module.css"


export default function StillWithDoubts() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.leftSide}>
          <div className={styles.leftTitle}>
            ¿Aun con dudas?
          </div>
        </div>
        <div className={styles.rightSide}>
          <div className={styles.rightTitle}>
            En Industrial Villarreal brindamos un servicio altamente personalizado de principio a fin. No dudes en comunicarte con nosotros.
          </div>
          <div className={styles.rightContent}>
            <div 
              onClick={() => 
                  window.location.href = "mailto: ivi_mty@hotmail.com?subject=Información Pagina Web&body=Hola, encontré este correo en su pagina web. Me encantaría recibir mas información de sus productos."
              }
              style={{cursor: "pointer"}}
              className={styles.contentButton}>
              <p> ivi_mty@hotmail.com </p>
            </div>
            <div 
              onClick={() =>
                  window.open("https://wa.me/528112129133?text=Hola,%20encontré%20este%20numero%20en%20su%20pagina%20web.%20Me%20encantaría%20recibir%20mas%20información%20de%20sus%20productos.")
              }
              style={{cursor: "pointer"}}
              className={styles.contentButton}>
              <p> 8112129133 </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) 
};
