import ThreeColumns from "../../newcomponents/three-columns";
import styles from "./styles.module.css";
import { Icon } from '@iconify/react';
import { Reveal } from "react-awesome-reveal";
import {keyframes} from "@emotion/react";

const fadeScale = keyframes`
  from {
    opacity: 0;
    transform: scale(1.2);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
`;

export default function ThreeColumnsSection() {
  return (
    <div className={styles.container}>
      {
      //     <Reveal keyframes={fadeScale} fraction={.5}delay={100} triggerOnce>
      // <p className={styles.title}>CONTRATAR EQUIPO NUNCA HABIA SIDO TAN FACIL</p>
      //     </Reveal>
      }
      <ThreeColumns>
        <div className={styles.column}>
          <div className={styles.subtitle}>
            <p>1</p>
            <p>HABLANOS</p>
          </div>
          <p className={styles.description}>
            Comunicate mediante  un mensaje en esta pagina, por WhatsApp, correo, o llamada y platicamos de tus necesidades
          </p>
        </div>
        <div className={styles.column}>
          <div className={styles.subtitle}>
            <p>2</p>
            <p>COTIZA</p>
          </div>
          <p className={styles.description}>
            Te ayudamos a seleccionar el equipo necesario para tu proyecto, aterrizar tiempos, y formas de pago.  
          </p>
        </div>
        <div className={styles.column}>
          <div className={styles.subtitle}>
            <p>3</p>
            <p>CONTRATA</p>
          </div>
          <p className={styles.description}>
            Por ultimo, nosotros nos encargamos de brindarte nuestros productos y servicios a tiempo con la mayor facilidad.
          </p>
        </div>
      </ThreeColumns>
      <div className={styles.mailPhoneWhatsapp}>
        <div className={styles.button}>
          <Icon icon="ic:baseline-whatsapp" className={styles.icon} color="white" />
        </div>
        <div className={styles.button}>
          <Icon icon="material-symbols:phone-callback"className={styles.icon}  color="white" />
        </div>
        <div className={styles.button}>
          <Icon icon="material-symbols:mail" className={styles.icon} color="white" />
        </div>
      </div>
    </div>
  );
}
