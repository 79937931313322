import logo from './logo.svg';
import Header from "./components/header";
import ContactHeader from "./components/contactheader";
import Carousel from "./components/carousel";
// linebreak, new imports
import OverNav from "./sections/overnav";
import NavBar from "./sections/navbar";
import SubBanner from "./sections/subbanner";
import ProductSlider from "./sections/productslider";
import "./app.css"
import ImageText from "./sections/twoblock/ImageText";
import Testimonials from "./sections/testimonials";
import PhotoBreak from "./sections/photobreak";
import InfoSlider from "./sections/infoslider";
import VideoHero from "./sections/videohero";
import Footer from "./sections/footer";
//import ProductViews from "./sections/productviews";
import ProductLines from "./sections/productlines/version2";
import FeaturesSlider from "./sections/featuresslider";
import ThreeColumnsSection from "./sections/threecolumnssection"
import HelpMeChoose from "./sections/helpmechoose";
import SafeChoice from "./sections/safechoice";
import StillWithDoubts from "./sections/stillwithdoubts"
import FooterTwo from "./sections/footertwo"
import TwoColumnsSection from "./sections/twocolumnssection"
import NewVideoHero from "./sections/newvideohero/index";
import ParWaySlider from "./sections/parkwayslider";
import Catalogo from "./sections/catalogo";
import "./buttons.css"
import MiddleBanner from "./sections/middlebanner";

function App() {
  return (
    <div className="App">
      <OverNav />
      <NavBar />
      <NewVideoHero />
      {
      // <Carousel />
      }
      <MiddleBanner />
      <ProductLines />
      <ParWaySlider />
      <ThreeColumnsSection/>
      {
      //<Testimonials />
      }
      <Catalogo />
      <PhotoBreak />
      {
      // <HelpMeChoose />
      }
      <SafeChoice />
      <StillWithDoubts/>
      <FooterTwo/>
      {
      // <FeaturesSlider />
      // <ProductSlider />
      // <ImageText />
      // <InfoSlider />
      // <VideoHero />
      // <Footer />
      }
      <div className="floatingButtons">
        <a 
          target="_blank"
          href="https://wa.me/528112129133?text=Hola,%20encontré%20este%20numero%20en%20su%20pagina%20web.%20Me%20encantaría%20recibir%20mas%20información%20de%20sus%20productos.">
        <div 
          href="https://wa.me/528112129133"
          className="whatsapp">
          <img 
            src="https://firebasestorage.googleapis.com/v0/b/industrialvillarreal-87d3b.appspot.com/o/whatsappemailholders.001.png?alt=media&token=a53f4fe3-a20e-4580-8c79-2c3322cfd88d"
          className="whatsappimg"/>
        </div>
        </a>
        <a 
          target="_blank"
          href="mailto: ivi_mty@hotmail.com?subject=Información Pagina Web&body=Hola, encontré este correo en su pagina web. Me encantaría recibir mas información de sus productos.">
        <div className="email">
          <img 
            src="https://firebasestorage.googleapis.com/v0/b/industrialvillarreal-87d3b.appspot.com/o/whatsappemailholders.002.png?alt=media&token=a53f4fe3-a20e-4580-8c79-2c3322cfd88d"
          className="emailimg"/>
        </div>
        </a>
      </div>
    </div>
  );
}

export default App;
