import styles from "./styles.module.css"
import { useRef, useEffect,useState } from "react"
import { keyframes } from "@emotion/react";
import { Reveal } from "react-awesome-reveal";

const fadeUp = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;



const titles = [
  "Confia en los mejores, confia en Industrial Villarreal",
  "La Mejor Calidad del pais",
  "Siempre priorizando tu tiempo y tranquilidad",
  "La seguridad tuya y de tu equipo siempre primero",
  "Espera un producto y servicio funcional por años",
]

const paragraphs = [
  "Con años de experiencia, hemos evolucionado nuestro servicio de manera constante para ser pioneros en la industria. Nos enorgullecemos de contar con 4 pilares que garantizan un servicio y productos de extremo valor. Calidad, Tiempo, Seguridad, y Garantía, han sido cualidades que asegurar que tengas el mejor producto siempre.",
  "Nuestra compañía se enorgullece en ofrecer camiones de servicio de alta calidad a nuestros clientes de la industria de la construcción. Utilizamos materiales de primera calidad y tecnologías avanzadas para asegurarnos de que cada uno de nuestros vehículos cumpla con los estándares más exigentes de durabilidad y rendimiento.",
  "En nuestra compañía, entendemos la importancia de tener un vehículo de servicio confiable y que funcione de manera eficiente en todo momento. Por eso, ofrecemos una garantía de tiempo en todos nuestros camiones de servicio, para garantizar que nuestros clientes puedan trabajar con tranquilidad y sin preocupaciones.",
  "La seguridad es una prioridad en nuestra compañía. Por eso, diseñamos y construimos cada uno de nuestros camiones de servicio con un enfoque en la seguridad, desde los sistemas de frenos hasta las características de protección contra incendios.",
  "En nuestra compañía, ofrecemos una garantía en todos nuestros camiones de servicio, para garantizar la satisfacción total de nuestros clientes. Si por alguna razón nuestro camion no cumple con sus expectativas o requerimientos, estamos dispuestos a trabajar con usted para resolver el problema de la manera más eficiente y efectiva posible.",
]


export default function SafeChoice() {
  const [content, setContent] = useState(0)
  const myRef = useRef(null)

  useEffect(() => {
    const timer = setTimeout(() => {
      console.log('This will run after 1 second!')
      document.getElementsByClassName(styles.form)[0].style.opacity = 1;
    }, 200);
    return () => clearTimeout(timer);
  },[content]);

  const handleOpacityChange = (step) => {
    if (step !== content) {
    document.getElementsByClassName(styles.form)[0].style.opacity = 0;
    const timer = setTimeout(() => {
      console.log('This will run after 1 second!')
      setContent(step);
    }, 200);
    return () => clearTimeout(timer);
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.form}>
          <div className={styles.formTitle}>
            <p>
            {titles[content]}</p>
          </div>
          <div className={styles.formContent}>
            <p>
            {paragraphs[content]}
            </p>
          </div>
        </div>
        <div className={styles.title}>
          <h1>Los 4 pilares de Industrial Villarreal</h1>
          <div className={styles.hoverselectors}>
            <div 
              className={styles.hoveritem}
              onMouseEnter={() => handleOpacityChange(1)}
              onClick={() => handleOpacityChange(1)}
            >
              <p>Calidad</p>
            </div>
            <div 
              className={styles.hoveritem}
              onMouseEnter={() => handleOpacityChange(2)}
              onClick={() => handleOpacityChange(2)}
            >
              <p>Tiempo</p>
            </div>
            <div 
              className={styles.hoveritem}
              onMouseEnter={() => handleOpacityChange(3)}
              onClick={() => handleOpacityChange(3)}
            >
              <p>Seguridad</p>
            </div>
            <div 
              className={styles.hoveritem}
              onMouseEnter={() => handleOpacityChange(4)}
              onClick={() => handleOpacityChange(4)}
            >
              <p>Garantia</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

};
  
