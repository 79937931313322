import {Fragment, useRef} from 'react';
import Logo from "../../assets/gladiator-white.png";
import "./styles.css";

export default function NavBar() {

  const navRef = useRef(null);

  const handleCheck = (e) => {
    if(e.target.checked) {
      navRef.current.classList.remove("closed");
      navRef.current.classList.add("opened");
      // navRef.current.style.opacity = "1";
      // navRef.current.style.display = "block";
    }
    if(!e.target.checked) {
      navRef.current.classList.remove("opened");
      navRef.current.classList.add("closed");
      // navRef.current.style.opacity = "0";
      // navRef.current.style.display = "none";
    }
  }

  return (
    <div class="header">
      <div class="genius">
        <img src={
          "https://firebasestorage.googleapis.com/v0/b/industrialvillarreal-87d3b.appspot.com/o/industrialvillarreal-logos.022.png?alt=media&token=f745237e-846c-44d4-9de6-6a707e5f8320"
        } alt="logo" />
        <div class="menu">
          <input onChange={handleCheck} class="side-menu" type="checkbox" id="side-menu"/>
          <label class="hamb" for="side-menu"><span class="hamb-line"></span></label>
        </div>
      </div>
      <nav ref={navRef} id="nav">
        <ul>
          <li><a href="#">Home</a></li>
        </ul>
      </nav>
    </div>
  );
}
