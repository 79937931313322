import styles from "./index.module.css";

export default function ParkWaySlider() {
  
  return (
    <article className={styles.wrapper}>
        <p className={styles.title}>En Industrial Villarreal trabajamos con las mejores marcas, conoce algunos de nuestros proveedores y compañeros.</p>
      <div className={styles.marquee}>
        <div className={styles.marquee__group}>
          <div className={styles.marquee__item}>
            <img src="https://firebasestorage.googleapis.com/v0/b/industrialvillarreal-87d3b.appspot.com/o/INDUSTRIAL-BRANDS.001.png?alt=media&token=76dcd265-e66b-4582-af52-4446bc46e405"/>
          </div>
          <div className={styles.marquee__item}>
            <img src="https://firebasestorage.googleapis.com/v0/b/industrialvillarreal-87d3b.appspot.com/o/INDUSTRIAL-BRANDS.002.png?alt=media&token=76dcd265-e66b-4582-af52-4446bc46e405"/>
          </div>
          <div className={styles.marquee__item}>
            <img src="https://firebasestorage.googleapis.com/v0/b/industrialvillarreal-87d3b.appspot.com/o/INDUSTRIAL-BRANDS.003.png?alt=media&token=76dcd265-e66b-4582-af52-4446bc46e405"/>
          </div>
          <div className={styles.marquee__item}>
            <img src="https://firebasestorage.googleapis.com/v0/b/industrialvillarreal-87d3b.appspot.com/o/INDUSTRIAL-BRANDS.004.png?alt=media&token=76dcd265-e66b-4582-af52-4446bc46e405"/>
          </div>
          <div className={styles.marquee__item}>
            <img src="https://firebasestorage.googleapis.com/v0/b/industrialvillarreal-87d3b.appspot.com/o/INDUSTRIAL-BRANDS.005.png?alt=media&token=76dcd265-e66b-4582-af52-4446bc46e405"/>
          </div>
          <div className={styles.marquee__item}>
            <img src="https://firebasestorage.googleapis.com/v0/b/industrialvillarreal-87d3b.appspot.com/o/INDUSTRIAL-BRANDS.006.png?alt=media&token=76dcd265-e66b-4582-af52-4446bc46e405"/>
          </div>
          <div className={styles.marquee__item}>
            <img src="https://firebasestorage.googleapis.com/v0/b/industrialvillarreal-87d3b.appspot.com/o/INDUSTRIAL-BRANDS.007.png?alt=media&token=76dcd265-e66b-4582-af52-4446bc46e405"/>
          </div>
          <div className={styles.marquee__item}>
            <img src="https://firebasestorage.googleapis.com/v0/b/industrialvillarreal-87d3b.appspot.com/o/INDUSTRIAL-BRANDS.008.png?alt=media&token=76dcd265-e66b-4582-af52-4446bc46e405"/>
          </div>
          <div className={styles.marquee__item}>
            <img src="https://firebasestorage.googleapis.com/v0/b/industrialvillarreal-87d3b.appspot.com/o/INDUSTRIAL-BRANDS.009.png?alt=media&token=76dcd265-e66b-4582-af52-4446bc46e405"/>
          </div>
          <div className={styles.marquee__item}>
            <img src="https://firebasestorage.googleapis.com/v0/b/industrialvillarreal-87d3b.appspot.com/o/INDUSTRIAL-BRANDS.010.png?alt=media&token=76dcd265-e66b-4582-af52-4446bc46e405"/>
          </div>
        </div>
    
        <div aria-hidden="true" className={styles.marquee__group}>
          <div className={styles.marquee__item}>
            <img src="https://firebasestorage.googleapis.com/v0/b/industrialvillarreal-87d3b.appspot.com/o/INDUSTRIAL-BRANDS.001.png?alt=media&token=76dcd265-e66b-4582-af52-4446bc46e405"/>
          </div>
          <div className={styles.marquee__item}>
            <img src="https://firebasestorage.googleapis.com/v0/b/industrialvillarreal-87d3b.appspot.com/o/INDUSTRIAL-BRANDS.002.png?alt=media&token=76dcd265-e66b-4582-af52-4446bc46e405"/>
          </div>
          <div className={styles.marquee__item}>
            <img src="https://firebasestorage.googleapis.com/v0/b/industrialvillarreal-87d3b.appspot.com/o/INDUSTRIAL-BRANDS.003.png?alt=media&token=76dcd265-e66b-4582-af52-4446bc46e405"/>
          </div>
          <div className={styles.marquee__item}>
            <img src="https://firebasestorage.googleapis.com/v0/b/industrialvillarreal-87d3b.appspot.com/o/INDUSTRIAL-BRANDS.004.png?alt=media&token=76dcd265-e66b-4582-af52-4446bc46e405"/>
          </div>
          <div className={styles.marquee__item}>
            <img src="https://firebasestorage.googleapis.com/v0/b/industrialvillarreal-87d3b.appspot.com/o/INDUSTRIAL-BRANDS.005.png?alt=media&token=76dcd265-e66b-4582-af52-4446bc46e405"/>
          </div>
          <div className={styles.marquee__item}>
            <img src="https://firebasestorage.googleapis.com/v0/b/industrialvillarreal-87d3b.appspot.com/o/INDUSTRIAL-BRANDS.006.png?alt=media&token=76dcd265-e66b-4582-af52-4446bc46e405"/>
          </div>
          <div className={styles.marquee__item}>
            <img src="https://firebasestorage.googleapis.com/v0/b/industrialvillarreal-87d3b.appspot.com/o/INDUSTRIAL-BRANDS.007.png?alt=media&token=76dcd265-e66b-4582-af52-4446bc46e405"/>
          </div>
          <div className={styles.marquee__item}>
            <img src="https://firebasestorage.googleapis.com/v0/b/industrialvillarreal-87d3b.appspot.com/o/INDUSTRIAL-BRANDS.008.png?alt=media&token=76dcd265-e66b-4582-af52-4446bc46e405"/>
          </div>
          <div className={styles.marquee__item}>
            <img src="https://firebasestorage.googleapis.com/v0/b/industrialvillarreal-87d3b.appspot.com/o/INDUSTRIAL-BRANDS.009.png?alt=media&token=76dcd265-e66b-4582-af52-4446bc46e405"/>
          </div>
          <div className={styles.marquee__item}>
            <img src="https://firebasestorage.googleapis.com/v0/b/industrialvillarreal-87d3b.appspot.com/o/INDUSTRIAL-BRANDS.010.png?alt=media&token=76dcd265-e66b-4582-af52-4446bc46e405"/>
          </div>
        </div>
      </div>
    
      <div className={`${styles.marquee} ${styles.marquee__reverse}`}>
        <div className={styles.marquee__group}>
          <div className={styles.marquee__item}>
            <img src="https://firebasestorage.googleapis.com/v0/b/industrialvillarreal-87d3b.appspot.com/o/INDUSTRIAL-BRANDS.001.png?alt=media&token=76dcd265-e66b-4582-af52-4446bc46e405"/>
          </div>
          <div className={styles.marquee__item}>
            <img src="https://firebasestorage.googleapis.com/v0/b/industrialvillarreal-87d3b.appspot.com/o/INDUSTRIAL-BRANDS.002.png?alt=media&token=76dcd265-e66b-4582-af52-4446bc46e405"/>
          </div>
          <div className={styles.marquee__item}>
            <img src="https://firebasestorage.googleapis.com/v0/b/industrialvillarreal-87d3b.appspot.com/o/INDUSTRIAL-BRANDS.003.png?alt=media&token=76dcd265-e66b-4582-af52-4446bc46e405"/>
          </div>
          <div className={styles.marquee__item}>
            <img src="https://firebasestorage.googleapis.com/v0/b/industrialvillarreal-87d3b.appspot.com/o/INDUSTRIAL-BRANDS.004.png?alt=media&token=76dcd265-e66b-4582-af52-4446bc46e405"/>
          </div>
          <div className={styles.marquee__item}>
            <img src="https://firebasestorage.googleapis.com/v0/b/industrialvillarreal-87d3b.appspot.com/o/INDUSTRIAL-BRANDS.005.png?alt=media&token=76dcd265-e66b-4582-af52-4446bc46e405"/>
          </div>
          <div className={styles.marquee__item}>
            <img src="https://firebasestorage.googleapis.com/v0/b/industrialvillarreal-87d3b.appspot.com/o/INDUSTRIAL-BRANDS.006.png?alt=media&token=76dcd265-e66b-4582-af52-4446bc46e405"/>
          </div>
          <div className={styles.marquee__item}>
            <img src="https://firebasestorage.googleapis.com/v0/b/industrialvillarreal-87d3b.appspot.com/o/INDUSTRIAL-BRANDS.007.png?alt=media&token=76dcd265-e66b-4582-af52-4446bc46e405"/>
          </div>
          <div className={styles.marquee__item}>
            <img src="https://firebasestorage.googleapis.com/v0/b/industrialvillarreal-87d3b.appspot.com/o/INDUSTRIAL-BRANDS.008.png?alt=media&token=76dcd265-e66b-4582-af52-4446bc46e405"/>
          </div>
          <div className={styles.marquee__item}>
            <img src="https://firebasestorage.googleapis.com/v0/b/industrialvillarreal-87d3b.appspot.com/o/INDUSTRIAL-BRANDS.009.png?alt=media&token=76dcd265-e66b-4582-af52-4446bc46e405"/>
          </div>
          <div className={styles.marquee__item}>
            <img src="https://firebasestorage.googleapis.com/v0/b/industrialvillarreal-87d3b.appspot.com/o/INDUSTRIAL-BRANDS.010.png?alt=media&token=76dcd265-e66b-4582-af52-4446bc46e405"/>
          </div>
        </div>
    
        <div aria-hidden="true" className={styles.marquee__group}>
          <div className={styles.marquee__item}>
            <img src="https://firebasestorage.googleapis.com/v0/b/industrialvillarreal-87d3b.appspot.com/o/INDUSTRIAL-BRANDS.001.png?alt=media&token=76dcd265-e66b-4582-af52-4446bc46e405"/>
          </div>
          <div className={styles.marquee__item}>
            <img src="https://firebasestorage.googleapis.com/v0/b/industrialvillarreal-87d3b.appspot.com/o/INDUSTRIAL-BRANDS.002.png?alt=media&token=76dcd265-e66b-4582-af52-4446bc46e405"/>
          </div>
          <div className={styles.marquee__item}>
            <img src="https://firebasestorage.googleapis.com/v0/b/industrialvillarreal-87d3b.appspot.com/o/INDUSTRIAL-BRANDS.003.png?alt=media&token=76dcd265-e66b-4582-af52-4446bc46e405"/>
          </div>
          <div className={styles.marquee__item}>
            <img src="https://firebasestorage.googleapis.com/v0/b/industrialvillarreal-87d3b.appspot.com/o/INDUSTRIAL-BRANDS.004.png?alt=media&token=76dcd265-e66b-4582-af52-4446bc46e405"/>
          </div>
          <div className={styles.marquee__item}>
            <img src="https://firebasestorage.googleapis.com/v0/b/industrialvillarreal-87d3b.appspot.com/o/INDUSTRIAL-BRANDS.005.png?alt=media&token=76dcd265-e66b-4582-af52-4446bc46e405"/>
          </div>
          <div className={styles.marquee__item}>
            <img src="https://firebasestorage.googleapis.com/v0/b/industrialvillarreal-87d3b.appspot.com/o/INDUSTRIAL-BRANDS.006.png?alt=media&token=76dcd265-e66b-4582-af52-4446bc46e405"/>
          </div>
          <div className={styles.marquee__item}>
            <img src="https://firebasestorage.googleapis.com/v0/b/industrialvillarreal-87d3b.appspot.com/o/INDUSTRIAL-BRANDS.007.png?alt=media&token=76dcd265-e66b-4582-af52-4446bc46e405"/>
          </div>
          <div className={styles.marquee__item}>
            <img src="https://firebasestorage.googleapis.com/v0/b/industrialvillarreal-87d3b.appspot.com/o/INDUSTRIAL-BRANDS.008.png?alt=media&token=76dcd265-e66b-4582-af52-4446bc46e405"/>
          </div>
          <div className={styles.marquee__item}>
            <img src="https://firebasestorage.googleapis.com/v0/b/industrialvillarreal-87d3b.appspot.com/o/INDUSTRIAL-BRANDS.009.png?alt=media&token=76dcd265-e66b-4582-af52-4446bc46e405"/>
          </div>
          <div className={styles.marquee__item}>
            <img src="https://firebasestorage.googleapis.com/v0/b/industrialvillarreal-87d3b.appspot.com/o/INDUSTRIAL-BRANDS.010.png?alt=media&token=76dcd265-e66b-4582-af52-4446bc46e405"/>
          </div>
        </div>
      </div>
    </article>
  )
}
