import styles from "./styles.module.css"
import { useRef, useEffect,useState } from "react"
import {keyframes} from "@emotion/react";
import {Reveal} from "react-awesome-reveal";

const fade = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const formTitles = [
  "First, what would you like to store in your safe?",
  "What level of security and protection would you like?",
  "How big do you need your safe to be?",
]

const formOptions = [
  [
    {name: "Small Value Goods", value: "cash"},
    {name: "Guns & Weaponry", value: "jewelry"},
    {name: "Documents & Records", value: "documents"},
  ],
  [
    {name: "Industry Standard", value: "standard"},
    {name: "High Security", value: "high"},
    {name: "Military Grade", value: "military"},
    {name: "Fire Resistant", value: "fire"},
  ],
  [
    {name: "Small", value: "small"},
    {name: "Medium", value: "medium"},
    {name: "Large", value: "large"},
  ],
]


export default function HelpMeChoose() {
  const [step, setStep] = useState(0);
  const [optionOne, setOptionOne] = useState("");
  const [optionTwo, setOptionTwo] = useState("");
  const [optionThree, setOptionThree] = useState("");

  useEffect(() => {
    const timerOne = setTimeout(() => {
      document.getElementsByClassName(styles.form)[0].style.opacity = 1;
    }, 250);
    return () => clearTimeout(timerOne);
  },[step]);

  const handleOpacityChange = (s) => {
    if (s !== step) {
      document.getElementsByClassName(styles.form)[0].style.opacity = 0;
      const timer = setTimeout(() => {
        setStep(s);
      }, 250);  
      return () => clearTimeout(timer);
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.title}>
          <h1>
            <Reveal keyframes={fade} fraction={.7} delay={300} triggerOnce>
          Help Me Choose
              </Reveal>
            </h1>
        </div>
        <div className={styles.form}>
        <Reveal keyframes={fade} fraction={.7} delay={300} triggerOnce>
          <div className={styles.formTitle}>
            <p>{formTitles[step]}</p>
          </div>
          <div className={styles.formContent}>
            {
              step !== 3 ? (
                formOptions[step].map((option, index) => (
                <button 
                  className={styles.formButton}
                  key={index} 
                  style={{color: "black"}}
                  onClick={() => {
                    handleOpacityChange(step + 1)
                    switch(step) {
                      case 0:
                        setOptionOne(option.value);
                        break;
                      case 1:
                        setOptionTwo(option.value);
                        break;
                      case 2:
                        setOptionThree(option.value);
                        break;
                      default:
                        break;
                    }
                  }}
                >
                  {option.name}
                </button>
              )))
              : (
                <></>
              )
            }
          </div>
          </Reveal>
        </div>
      </div>
    </div>
  )

};
  
