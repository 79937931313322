import React, { useState } from 'react';
import { Document, Page, pdfjs} from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import './indexCat.css';

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
  standardFontDataUrl: 'standard_fonts/',
};

export default function Sample() {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }

  const downloadPdf = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = "catalogo-industrial";
    link.click();
  };

  const pdfToBlob = (pdf) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", pdf);
    xhr.responseType = "blob";
    console.log(xhr);
    xhr.onload = () => {
      const blob = xhr.response;
      console.log(blob);
      const url = URL.createObjectURL(blob);
      downloadPdf(url);
    };
    xhr.send();
  };

  return (
    <div className="Example">
        <div className="Example__container__document">
          <Document file={
            "https://firebasestorage.googleapis.com/v0/b/industrialvillarreal-87d3b.appspot.com/o/industrial-catalogo-web.pdf?alt=media&token=34a46889-68aa-4640-b5c7-11b5e7285b07"
            } onLoadSuccess={onDocumentLoadSuccess} options={options}>
              <Page  pageNumber={pageNumber} />
          </Document>
          <div className="pdfButtons">
            <div 
              onClick={() => {
                if (pageNumber !== 1) {
                setPageNumber(pageNumber - 1)
                }
              }}
              className="pdfButton">
              Pagina Anterior
            </div>
            <div 
              onClick={() => {
                if (pageNumber !== 5) {
                  setPageNumber(pageNumber + 1)
                }
              }}
              className="pdfButton">
              Pagina Siguiente
            </div>
            <div 
              className="pdfButton"
              onClick={() => {
                pdfToBlob("https://firebasestorage.googleapis.com/v0/b/industrialvillarreal-87d3b.appspot.com/o/industrial-catalogo-web.pdf?alt=media&token=34a46889-68aa-4640-b5c7-11b5e7285b07")
              }}
            >
              Descargar Pdf
            </div>
          </div>
        </div>
    </div>
  );
}
