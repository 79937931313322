import styles from "./MiddleBanner.module.css";
import { Reveal } from "react-awesome-reveal";
import { keyframes } from "@emotion/react";

const fadeScale = keyframes`
  from {
    opacity: 0;
    transform: scale(1.2);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
`;

export default function MiddleBanner() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.item}>
        <Reveal keyframes={fadeScale} fraction={.5}delay={100} triggerOnce>
          <div className={styles.text}>
            <span className={styles.highlight}>Industrial Villarreal</span> es pionero en fabricación e instalación de <span className={styles.highlight}>Orquestas, Marimbas, Camiones Mecanicos, Unidades de Rescate, Medicas, y Bomberos.</span> Con el paso del tiempo, nuestros servicios se han expandido a la venta de equipo individual. Conoce debajo todos los productos que ofrecemos.
          </div>
        </Reveal>
      </div>
      <div className={styles.item}>
        <Reveal keyframes={fadeScale} fraction={.5}delay={100} triggerOnce>
          <div className={styles.title}>
            Soluciones Integrales en Bombas y Sistemas de Lubicración Industrial
          </div>
        </Reveal>
      </div>
    </div>
  )
}
