import {Fragment} from 'react';
import Facebook from "../../assets/facebook.png";
import Insta from "../../assets/insta.png";
import Twitter from "../../assets/twitter.png";
import Youtube from "../../assets/youtube.png";
import Phone from "../../assets/phone.png";
import Mail from "../../assets/mail.png";
import styles from "./styles.module.css";

export default function OverNav() {
  return (
    <Fragment>
      <div className={styles.promotionbar}> 
        <p className={styles.promotionbarp}>Conoce Industrial Villarreal, la solucion ideal para talleres, unidades de servicio, mecanicos y de emergencia.</p>
      </div>
      <div className={styles.contactbar}>
        <div className={styles.socials}>
          <img src={Facebook} alt="facebook" className={styles.socialsImg} />
          <img src={Insta} alt="instagram" className={styles.socialsImg} />
          <img src={Twitter} alt="twitter" className={styles.socialsImg} />
          <img src={Youtube} alt="youtube" className={styles.socialsImg} />
        </div>
        <div className={styles.mpcontainer}>
          <img src={Phone} alt="phone" className={styles.mpImg}/>
          <p className={styles.mpp}>
            <a 
              style={{color: "white", textDecoration: "none"}}
              href="tel:+528183743417">
          +52 818 374 3417
            </a>
          </p>
        </div>
        <div className={styles.mpcontainer}>
          <img src={Mail} alt="mail" className={styles.mpImg}/>
          <p className={styles.mpp}>ivi_mty@hotmail.com</p>
        </div>
      </div>
    </Fragment>
  );
}
