import styles from "./styles.module.css"


export default function FooterTwo() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.contactBanner}>
          <img src="https://firebasestorage.googleapis.com/v0/b/industrialvillarreal-87d3b.appspot.com/o/industrialvillarreal-banner.001.png?alt=media&token=f8247fb3-920b-4878-a185-4b4f47309915"/>
          <p>
            ivi_mty@hotmail.com
          </p>
          <p>
            8112129133
          </p>
          <h6>
            © 2021 Industrial Villarreal. built by reezylach
          </h6>
        </div>
      </div>
    </div>
  )
}


