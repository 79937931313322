import styles from "./styles.module.css";
import Break01 from "../../assets/break01.jpg";
import Break02 from "../../assets/break02.jpg";
import Break03 from "../../assets/break03.jpg";




export default function PhotoBreak() {
  return (
    <div className={styles.container}>
      <img className={styles.image} src={"https://firebasestorage.googleapis.com/v0/b/industrialvillarreal-87d3b.appspot.com/o/17_MDTY_Dump-copy.png?alt=media&token=8936c9f7-6483-46b2-a671-60c002e8ffce"} alt="Photo" />
      <img className={styles.image} src={"https://firebasestorage.googleapis.com/v0/b/industrialvillarreal-87d3b.appspot.com/o/160920---Mack-Granite-Concrete-Mixer-Model_ENRready.png?alt=media&token=9e135a9d-46eb-4e33-8568-41d5ca81af8d"} alt="Photo" />
    </div>
  );
}
