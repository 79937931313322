import {useState, useEffect} from "react";
import { keyframes } from "@emotion/react";
import { Reveal } from "react-awesome-reveal";
import styles from "./styles.module.css";
import Slider from "react-slick";
import One from "../../assets/products/01.png";
import Two from "../../assets/products/02.png";
import Three from "../../assets/products/03.png";
import Four from "../../assets/products/04.png";
import Five from "../../assets/products/05.png";
import Six from "../../assets/products/06.png";
import PillOne from "../../assets/products/pill01.png";
import PillTwo from "../../assets/products/pill02.png";
import PillThree from "../../assets/products/pill03.png";
import PillFour from "../../assets/products/pill04.png";
import PillFive from "../../assets/products/pill05.png";
import PillSix from "../../assets/products/pill06.png";

const fadeUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(100px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;
const fade = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;
const fadeScale = keyframes`
  from {
    opacity: 0;
    transform: scale(1.2);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const categories = {
  "Lubricación": [
    "Aceites automotrices minerales y sintéticos, grasas automotrices",
    "Equipos de lubricación automotrices y centralizada",
    "Carretes, bombas neumáticas y manuales de aceite", 
    "Pistolas marcadoras, mecánicas, digitales y antigoteo de aceite y grasa", 
    "Inyectores de jeringa neumáticos y manuales", 
    "Instalación de equipo de lubricación en isletas", 
    "Lubricadores de gravedad, mirillas y niveles de aceite rectos, tipo l y tipo t", 
    "Extractores de aceite con válvula de drene y bayonetas",  
    "Kit contenedor de 1000 lts para aceite equipado con bomba carrete y pistola despachadora para aceite o abdule", 
    "Desengrasantes químicos",  
    "Dispensadores de aceite"
  ],
  "Equipo de Construcción": [
    "Apisonadoras de concreto a gasolina", 
    "Fabricación e instalación de camiones orquesta y de camiones mecánicos",  
    "Instalación de grúas hidráulicas importadas balanceadoras", 
    "Equipo sand blast",
    "Equipo y herramienta agrícola", 
    "Equipo de riego sumergibles eléctricos", 
    "Elevadores de tanques", 
    "Enderezadores de chasis y dozer", 
    "Graseras estándar y de botón",  
    "Gastos hidráulicos de botella, de pistón mecánicos y de transmisión, transportadoras de patín", 
    "Hidrolavadoras automotrices eléctricas, de diésel y a gasolina domésticos, comerciales y trabajo pesado y unidades de lavado", 
    "Martillo demoledor", 
    "Martillos multicabeza para quitar tornillos a equipo pesado", 
    "Motores eléctricos y de gasolina", 
    "Pistolas de impacto", 
    "Polipastos", 
    "Pumas hidráulicas y manuales",  
    "Pulidores", 
    "Prensas hidráulicas", 
    "Placas vibradoras", 
    "Recibidores de aceite", 
    "Rampas de pistón y electrohidráulicas, freneras y para estacionamientos", 
    "Revolvedoras de concreto", 
    "Rotomartillos y coladoras compactas", 
    "Sierras de concreto", 
    "Transportadora de tarimas", 
    "Revolvedoras de concreto", 
    "Rotomartillos y coladoras compactas"
  ],
  "Bombas, Tanques y Generadores": [
    "Bombas de combustible eléctricas, neumáticas y jarra patrón",   
    "Bombas neumáticas de diafragma para trasferencia de líquidos, combustible o gasolina",   
    "Bombas eléctricas para transferencia de abdule",   
    "Bombas de diafragma para agua limpia y lodoza, productos químicos y alimenticios, solventes, aceites etc.",    
    "Bombas centrifugas y de pozo profundo eléctricas y de gasolina",   
    "Tanques cilíndricos con entrada para suministrar agua o Diesel",   
    "Tanques para compresores de aire",   
    "Tanques estacionarios con niveles y respiraderos y válvulas para combustible o agua",
    "Generadores a gasolina y eléctricos",
    "Llanas a gasolina",
    "Manómetros de presión y de glicerina",
    "Medidores /marcadores mecánicos, manuales y con impresora de Diesel, aceite y gasolina",
  ],
  "Seguridad y Otros": [
    "Aspiradoras domésticas e industriales alineadoras",
    "Desmontadoras de llantas",
    "Equipo y herramienta para taller mecánico",
    "Equipo y herramienta para taller de enderezado y pintura",
    "Equipo y herramienta agrícola",
    "Equipo de riego sumergibles eléctricos",
    "Soldadoras eléctricas",
    "Equipo de Seguridad y Señalamiento",
  ],
}

const lines = [
  {"name": "residential", "image": PillOne},
  {"name":"gun safes", "image": PillTwo},
  {"name":"raffle", "image": PillThree},
  {"name":"everyday", "image": PillFour},
]

const families = {
  "residential": ["HOTEL", "ROYAL", "FORZA", "CRIXUS"],
  "gun safes": ["CABINET", "SPARTAN", "CENTURION", ],
  "raffle": ["RL001", "3 LTS", "CA50T"],
  "everyday": ["model 1", "model 2",],
}

const products = {
  "FORZA": [
    {"name": "Forza80", "title": "Meet Forza 80", "image": PillOne, "desOne": "hdsjjfdoiub", "desTwo": "uhvwajoeik", "desThree":"uhqiceajokavojebhsw"},
    {"name": "Forza60", "title": "Meet Forza 60", "image": PillTwo, "desOne": "hdsjjfdoiub", "desTwo": "uhvwajoeik", "desThree":"uhqiceajokavojebhsw"},
    {"name": "Forza40", "title": "Meet Forza 40", "image": PillThree, "desOne": "hdsjjfdoiub", "desTwo": "uhvwajoeik", "desThree":"uhqiceajokavojebhsw"},
  ],
  "CRIXUS": [
    {"name": "Crixus80", "title": "Meet Crixus 80", "image": PillFour, "desOne": "hdsjjfdoiub", "desTwo": "uhvwajoeik", "desThree":"uhqiceajokavojebhsw"},
    {"name": "Crixus60", "title": "Meet Crixus 60", "image": PillFive, "desOne": "hdsjjfdoiub", "desTwo": "uhvwajoeik", "desThree":"uhqiceajokavojebhsw"},
  ],
  "CENTURION": [
    {"name": "CENTURION01", "title": "Meet Forza 60", "image": PillTwo, "desOne": "hdsjjfdoiub", "desTwo": "uhvwajoeik", "desThree":"uhqiceajokavojebhsw"},
    {"name": "CENTURION02", "title": "Meet Crixus 60", "image": PillFive, "desOne": "hdsjjfdoiub", "desTwo": "uhvwajoeik", "desThree":"uhqiceajokavojebhsw"},
  ],
  "SPARTAN": [
    {"name": "SPARTANx1", "title": "Meet Forza 60", "image": PillTwo, "desOne": "hdsjjfdoiub", "desTwo": "uhvwajoeik", "desThree":"uhqiceajokavojebhsw"},
    {"name": "SPARTANx2", "title": "Meet Forza 40", "image": PillThree, "desOne": "hdsjjfdoiub", "desTwo": "uhvwajoeik", "desThree":"uhqiceajokavojebhsw"},
    {"name": "SPARTANx3", "title": "Meet Crixus 60", "image": PillFive, "desOne": "hdsjjfdoiub", "desTwo": "uhvwajoeik", "desThree":"uhqiceajokavojebhsw"},
  ],
  "CABINET": [
    {"name": "Cabinet80", "title": "Meet Crixus 80", "image": PillFour, "desOne": "hdsjjfdoiub", "desTwo": "uhvwajoeik", "desThree":"uhqiceajokavojebhsw"},
    {"name": "Cabinet60", "title": "Meet Crixus 60", "image": PillFive, "desOne": "hdsjjfdoiub", "desTwo": "uhvwajoeik", "desThree":"uhqiceajokavojebhsw"},
  ],
  "ROYAL": [
    {"name": "RoyalM1", "title": "Meet Forza 60", "image": PillTwo, "desOne": "hdsjjfdoiub", "desTwo": "uhvwajoeik", "desThree":"uhqiceajokavojebhsw"},
    {"name": "RoyalM2", "title": "Meet Forza 40", "image": PillThree, "desOne": "hdsjjfdoiub", "desTwo": "uhvwajoeik", "desThree":"uhqiceajokavojebhsw"},
    {"name": "RoyalM3", "title": "Meet Crixus 60", "image": PillFive, "desOne": "hdsjjfdoiub", "desTwo": "uhvwajoeik", "desThree":"uhqiceajokavojebhsw"},
  ],
  "HOTEL": [
    {"name": "Hotel One", "title": "Meet Crixus 80", "image": PillFour, "desOne": "hdsjjfdoiub", "desTwo": "uhvwajoeik", "desThree":"uhqiceajokavojebhsw"},
    {"name": "Hotel Two", "title": "Meet Crixus 60", "image": PillFive, "desOne": "hdsjjfdoiub", "desTwo": "uhvwajoeik", "desThree":"uhqiceajokavojebhsw"},
  ],
  "RL001": [
    {"name": "RL001", "title": "Meet Forza 60", "image": PillTwo, "desOne": "hdsjjfdoiub", "desTwo": "uhvwajoeik", "desThree":"uhqiceajokavojebhsw"},
  ],
  "3 LTS": [
    {"name": "3 LTS 01", "title": "Meet Forza 60", "image": PillTwo, "desOne": "hdsjjfdoiub", "desTwo": "uhvwajoeik", "desThree":"uhqiceajokavojebhsw"},
    {"name": "3 LTS 02", "title": "Meet Forza 40", "image": PillThree, "desOne": "hdsjjfdoiub", "desTwo": "uhvwajoeik", "desThree":"uhqiceajokavojebhsw"},
  ],
  "CA50T": [
    {"name": "CA50T", "title": "Meet Crixus 80", "image": PillFour, "desOne": "hdsjjfdoiub", "desTwo": "uhvwajoeik", "desThree":"uhqiceajokavojebhsw"},
    {"name": "CA50TP", "title": "Meet Crixus 60", "image": PillFive, "desOne": "hdsjjfdoiub", "desTwo": "uhvwajoeik", "desThree":"uhqiceajokavojebhsw"},
  ],
  "model 1": [
    {"name": "model 1 v", "title": "Meet Forza 60", "image": PillTwo, "desOne": "hdsjjfdoiub", "desTwo": "uhvwajoeik", "desThree":"uhqiceajokavojebhsw"},
    {"name": "model 1 V", "title": "Meet Forza 40", "image": PillThree, "desOne": "hdsjjfdoiub", "desTwo": "uhvwajoeik", "desThree":"uhqiceajokavojebhsw"},
  ],
  "model 2": [
    {"name": "model 2 X", "title": "Meet Crixus 80", "image": PillFour, "desOne": "hdsjjfdoiub", "desTwo": "uhvwajoeik", "desThree":"uhqiceajokavojebhsw"},
    {"name": "model 2 X Plus", "title": "Meet Crixus 60", "image": PillFive, "desOne": "hdsjjfdoiub", "desTwo": "uhvwajoeik", "desThree":"uhqiceajokavojebhsw"},
  ],
}

export default function ProductLines() {
  const [line, setLine] = useState("Lubricación");
  const [family, setFamily] = useState("FORZA");
  const [product, setProduct] = useState(products["FORZA"][0]);
  const [toMap, setToMap] = useState([]);

  const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

  const handleLine = (line) => {
    setLine(line);
    setFamily(families[line][0]);
    setProduct(products[families[line][0]][0]);
  };

  const handleFamily = (family) => {
    setFamily(family);
    setProduct(products[family][0]);
  }

  useEffect(() => {
    setToMap([]);
    setToMap(categories[line]);
  }, [line]);


  return (
    <div className={styles.container}>
      <Reveal keyframes={fadeScale} fraction={.5}delay={100} triggerOnce>
        <p className={styles.title}>Conoce todos los productos y servicios que ofrecemos en Industrial Villarreal</p>
      </Reveal>
      <Reveal keyframes={fade} fraction={.5}delay={100} triggerOnce>
        <p className={styles.catTitle}>Selecciona una categoría</p>
      <div className={styles.lines}>
        {Object.keys(categories).map((item) => (
          <div 
            className={styles.line} key={item}
            onClick={() => setLine(item)}
            style={line === item ? {color: "#fff", borderBottom: "solid 2px #e43424"} : {}}
          >
            {item}
          </div>
        ))}
      </div>
        <p className={styles.catTitle}>Productos:</p>
      <div className={styles.families}>
        {toMap.map((item) => (
          <div 
            className={styles.indproduct} key={item}
          >
            {item}
          </div>
        ))}
      </div>
        {
      // <div className={styles.siblings}>
      //       <div className={styles.modelholder}>
      //         line models
      //       </div>
      //   {products[family].map((item) => (
      //       <div 
      //         className={styles.sibling} key={item.name}
      //         onClick={() => {setProduct(item)}}
      //         style={product === item ?{color: "#fff", borderBottom: "solid 2px #E43424"} : {}}
      //       >
      //         {item.name}
      //       </div>
      //   ))}
      // </div>
        }
      </Reveal>
      {
      // <div className={styles.product}>
      //   <div className={styles.productImgholder}>
      //     <Reveal keyframes={fade} triggerOnce>
      //       <img src={product.image} alt={product.name} />
      //     </Reveal>
      //   </div>
      //   <div className={styles.productInfo}>
      //     <Reveal keyframes={fade} triggerOnce>
      //       <p className={styles.productTitle}>{product.title}</p>
      //       <div className={styles.productBreak} />
      //       <p className={styles.productDes}>{product.desOne}</p>
      //       <p className={styles.productDes}>{product.desTwo}</p>
      //       <p className={styles.productDes}>{product.desThree}</p>
      //       <div className={styles.productBreak} />
      //       <div className={styles.productCall}>
      //         <div className={styles.productPrice}>
      //           $1,000
      //         </div>
      //         <div className={styles.productBtns}>
      //           <button className={styles.productBtn}>Learn More</button>
      //           <button className={styles.productBtn}>Buy Now</button>
      //         </div>
      //       </div>
      //     </Reveal>
      //   </div>
      // </div>
      }
    </div>
  );
}
