import { useState, useEffect } from 'react';
import styles from "./index.module.css";
import { Reveal } from "react-awesome-reveal";
import { keyframes } from "@emotion/react";

const fadeScale = keyframes`
  from {
    opacity: 0;
    transform: scale(1.2);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
`;

export default function Home() {

  

  return (
    <div className={styles.container}>
      <div className={styles.videoHero}>
        <div className={styles.videoHeroContent}>
          <img src="https://firebasestorage.googleapis.com/v0/b/industrialvillarreal-87d3b.appspot.com/o/industrialvillarreal-banner.001.png?alt=media&token=f8247fb3-920b-4878-a185-4b4f47309915"/>
          <p>
            Con mas de 35 años de experiencia, en Industrial Villarreal siempre te brindaremos el mejor servicio con el personal mas altamente capacitado.
          </p>
        </div>
        <video autoPlay muted loop className={styles.video}>
          <source src="https://firebasestorage.googleapis.com/v0/b/industrialvillarreal-87d3b.appspot.com/o/30%20-%20industrialvillarreal-video.m4v?alt=media&token=314b81f1-cd10-48f8-abcd-072bb77c8520"/>
        </video>
      </div>
      <div className={styles.titleBanner}>
        <div className={styles.titleBannerContent}>
          <Reveal keyframes={fadeScale} fraction={.5}delay={100} triggerOnce>
              <h1>Venta de <span className={styles.highlight}>Equipos</span> de agua, lubricación, combustible, y emergencias.</h1>
          </Reveal>
        </div>
        <div className={styles.titleBannerForm}>
          <Reveal keyframes={fadeScale} fraction={.5}delay={100} triggerOnce>
            <h4>¿Necesitas ayuda o quieres contactarte?</h4>
            <p>Ofrecemos servicio de asesoria y personalizado completamente gratis, dejanos tus datos y nos comunicamos en breve.</p>
            <div className={styles.buttons}>
            <button 
              className={styles.bannerFormButton}
              onClick={() => {
                window.location.href = "mailto: ivi_mty@hotmail.com?subject=Información Pagina Web&body=Hola, encontré este correo en su pagina web. Me encantaría recibir mas información de sus productos."
              }}
            >Correo</button>
            <button 
              className={styles.bannerFormButton}
              onClick={() => {
                window.open("https://wa.me/528112129133?text=Hola,%20encontré%20este%20numero%20en%20su%20pagina%20web.%20Me%20encantaría%20recibir%20mas%20información%20de%20sus%20productos.");
              }}
            >WhatsApp</button>
            </div>
          </Reveal>
        </div>
      </div>
		</div>
	)
}
